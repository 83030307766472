import { useEffect, useState } from 'react';
import './SpeedTestPage.scss';
import SpeedTest from '../../components/speed-test/SpeedTest';
import axios from 'axios';
import logger from "../../utils/logger";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';

export default function SpeedTestPage() {

    const { token } = useParams();

    const serverList = [
        {
            name: 'Roma',
            url: 'https://speedtestroma.enel.it',
            socketUrl: 'wss://speedtestroma.enel.it:3000'
        },
        {
            name: 'Milano',
            url: 'https://speedtestmilano.enel.it',
            socketUrl: 'wss://speedtestmilano.enel.it:3000'
        }
    ];

    // Map file sizes for different connection speeds
    const fileSizeMap = {
        'FTTC_1': [50, 40, 30, 20, 50, 40, 30, 20],
        'FTTC_2': [100, 200, 75, 100, 100, 200, 75, 100],
        'FTTC_3': [200, 300, 100, 300, 200, 300, 100, 300],
        'FTTC_4': [500, 100, 200, 400, 500, 100, 200, 400],
        'FTTH': [1024, 500, 750, 300, 1024, 500, 750, 300],
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const server = searchParams.get('server');

    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isCheckComplete, setCheckComplete] = useState(false);
    const [statusError, setStatusError] = useState<any>('');
    const [serverErrorVisible, setServerErrorVisible] = useState<boolean>(false);
    const [redirectUrl, setRedirectUrl] = useState<any>(null);
    const [tokenId, setTokenId] = useState<any>(null);
    const [countLeft, setCountLeft] = useState<number>(0);
    const [isSpeedTestVisible, setIsSpeedTestVisible] = useState(false);
    const [serverName, setServerName] = useState<string>('');
    const [preliminaryStarted, setPreliminaryStarted] = useState<boolean>(false);
    const [downloadStarted, setDownloadStarted] = useState<boolean>(false);
    const [uploadStarted, setUploadStarted] = useState<boolean>(false);
    const [downloadSpeed, setDownloadSpeed] = useState<number | null>(null);
    const [uploadSpeed, setUploadSpeed] = useState<any>('');
    const [isDownloadComplete, setIsDownloadComplete] = useState<boolean>(false);
    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);
    const [successPost, setSuccessPost] = useState<boolean>(false);
    const [ipAddress, setIpAddress] = useState<string>('');
    const [latency, setLatency] = useState<any>('');

    // Concurrency and test durations (in seconds):
    const DOWNLOAD_CONNECTIONS = 8;
    const UPLOAD_CONNECTIONS = 8;
    const DOWNLOAD_DURATION = 15;
    const UPLOAD_DURATION = 15;

    function updateRollingAverage(samples: number[], newSample: number, maxSamples: number = 20) {
        samples.push(newSample);
        if (samples.length > maxSamples) {
            samples.shift();
        }
        const sum = samples.reduce((acc, val) => acc + val, 0);
        return sum / samples.length;
    }

    function createRandomBlob(sizeInBytes = 1024 * 1024) {
        const chunkSize = 65536; // 64KB
        const buffer = new Uint8Array(sizeInBytes);
        let offset = 0;

        while (offset < sizeInBytes) {
            const length = Math.min(chunkSize, sizeInBytes - offset);
            const chunk = new Uint8Array(length);
            crypto.getRandomValues(chunk);
            buffer.set(chunk, offset);
            offset += length;
        }

        return new Blob([buffer], { type: 'application/octet-stream' });
    }

    // -------------------------------------------------------------------------
    // Token checks & preliminary logic
    // -------------------------------------------------------------------------
    async function checkTokenCount() {
        try {
            const response = await axios.get(`https://speedtestroma.enel.it/tokens/${token}/status`);
            if (response && response.data) {
                const countLeft = response.data.count_left;
                setCountLeft(parseInt(countLeft));
                setCheckComplete(true);
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    async function checkToken() {
        try {
            const response = await axios.get(`https://speedtestroma.enel.it/tokens/${token}/status`);

            if (response && response.data) {
                const idToken = response.data.id;
                const countLeft = response.data.count_left;

                if (parseInt(countLeft, 10) === 0) {
                    setStatusError("Tentativi esauriti. Non è possibile continuare.");
                    setRedirectUrl('/');
                    setModalOpen(true);
                    setCheckComplete(true);
                    return;
                }

                const now = new Date();
                const expiryDate = new Date(response.data.expiration_datetime.replace(" ", "T"));
                if (expiryDate <= now) {
                    setStatusError("Il token è scaduto. Non è possibile continuare.");
                    setRedirectUrl('/');
                    setModalOpen(true);
                    setCheckComplete(true);
                    return;
                }

                setTokenId(idToken);
                setCountLeft(parseInt(countLeft));
                setCheckComplete(true);
            } else {
                setModalOpen(true);
                setCheckComplete(true);
            }
        } catch (error: any) {
            if (error.response) {
                setStatusError(error.response.data);
            } else if (error.request) {
                setStatusError(error.request);
            } else {
                setStatusError(error.message);
            }
            setModalOpen(true);
            setCheckComplete(true);
        }
    }

    async function pingServer(url: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const iterations = 5;
            const ws = new WebSocket(url);
            const pingTimes: number[] = [];
            let i = 0;
            let isResolved = false;

            // general timeout (10 seconds)
            const generalTimeout = setTimeout(() => {
                if (!isResolved) {
                    isResolved = true;
                    ws.close();
                    reject(new Error('Timeout generale raggiunto durante il test di ping'));
                }
            }, 10000);

            ws.onopen = () => {
                logger.log("WebSocket connesso. Inizio test di ping...");

                const sendPing = (ip?: string) => {
                    if (i >= iterations) {
                        if (!isResolved) {
                            setIpAddress(ip ?? '');
                            isResolved = true;
                            ws.close();
                            const averagePing = pingTimes.reduce((a, b) => a + b, 0) / pingTimes.length;
                            logger.log(`Ping medio: ${averagePing.toFixed(2)} ms`);
                            resolve(Number(averagePing.toFixed(0)));
                        }
                        return;
                    }

                    const startTime = performance.now();
                    ws.send("ping");

                    // Timeout for individual ping
                    const pingTimeout = setTimeout(() => {
                        if (!isResolved) {
                            logger.warn(`Ping numero ${i + 1} non ha ricevuto risposta in tempo.`);
                            pingTimes.push(3000);
                            i++;
                            sendPing();
                        }
                    }, 3000);

                    ws.onmessage = (event) => {
                        clearTimeout(pingTimeout);
                        const elapsedTime = performance.now() - startTime;
                        pingTimes.push(elapsedTime);
                        logger.log(`Ping ${i + 1}: ${elapsedTime.toFixed(2)} ms`);
                        i++;
                        sendPing(event.data);
                    };
                };

                sendPing();
            };

            ws.onerror = (error: any) => {
                logger.error("Errore WebSocket:", error);
                if (!isResolved) {
                    isResolved = true;
                    clearTimeout(generalTimeout);
                    reject(new Error('Errore di connessione al server'));
                }
            };

            ws.onclose = () => {
                logger.log("WebSocket chiuso.");
                if (!isResolved && i < iterations) {
                    isResolved = true;
                    clearTimeout(generalTimeout);
                    reject(new Error('WebSocket chiuso prima del completamento del test di ping'));
                }
            };
        });
    }

    const performPreliminaryTest = async (serverUrl: string): Promise<number> => {
        const testFileSizeMB = 4;
        const url = `${serverUrl}/tr143/${testFileSizeMB}MB?cache_bust=${Date.now()}`;

        try {
            const response = await fetch(url, { cache: 'no-cache' });
            if (!response.ok || !response.body) {
                throw new Error(`Risposta di rete non valida: ${response.statusText}`);
            }

            const reader = response.body.getReader();
            let receivedBytes = 0;
            const startTime = performance.now();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                receivedBytes += value.length;
            }

            const endTime = performance.now();
            const durationSeconds = (endTime - startTime) / 1000;
            const finalSpeedMbps = (receivedBytes * 8) / (durationSeconds * 1000000);
            logger.log(`Velocità finale stimata: ${finalSpeedMbps.toFixed(2)} Mbps`);
            return finalSpeedMbps;
        } catch (error) {
            logger.error('Errore durante il test preliminare:', error);
            console.log(error);
            throw error;
        }
    };

    const performMultipleTests = async (serverUrl: string, numberOfTests: number = 4): Promise<number> => {
        const speeds = [];

        for (let i = 0; i < numberOfTests; i++) {
            try {
                const speed = await performPreliminaryTest(serverUrl);
                speeds.push(speed);
                await new Promise(res => setTimeout(res, 1000));
            } catch (error) {
                logger.error(`Test ${i + 1} fallito:`, error);
                console.log(error);
            }
        }

        if (speeds.length === 0) throw new Error('Tutti i test preliminari sono falliti.');

        const averageSpeed = speeds.reduce((a, b) => a + b, 0) / speeds.length;
        logger.log(`Velocità media stimata: ${averageSpeed.toFixed(2)} Mbps`);
        return averageSpeed;
    };

    // ------------------------------------------------------------------------
    // Download Test (concurrency-based)
    // ------------------------------------------------------------------------
    const startDownloadTest = async (serverUrl: string, fileSizeMB: number[]) => {
        setDownloadStarted(true);
        setDownloadSpeed(0);
        setIsDownloadComplete(false);

        let isActive = true;
        let totalBytes = 0;
        const speedSamples: number[] = [];
        const startTime = performance.now();

        // We'll use an AbortController for each worker to cancel fetch
        const controllers: AbortController[] = [];

        // Single download worker
        const downloadWorker = async (index: number) => {
            while (isActive) {
                try {
                    const controller = new AbortController();
                    controllers.push(controller);
                    const resp = await fetch(`${serverUrl}/web/${fileSizeMB[index]}MB?nocache=${Date.now()}&token=${token}`, {
                        signal: controller.signal,
                    });
                    if (!resp.ok || !resp.body) {
                        break;
                    }
                    const reader = resp.body.getReader();

                    while (true) {
                        const { done, value } = await reader.read();
                        if (!isActive) {
                            // stop reading if time expired
                            controller.abort();
                            break;
                        }
                        if (done || !value) break;

                        totalBytes += value.length;
                        const elapsedMs = performance.now() - startTime;
                        const elapsedSec = elapsedMs / 1000;
                        const currentSpeed = (totalBytes * 8) / (elapsedSec * 1e6); // Mbps

                        const avgSpeed = updateRollingAverage(speedSamples, currentSpeed);
                        setDownloadSpeed(Number(avgSpeed.toFixed(2)));
                    }
                } catch (err: any) {
                    if (err.name === 'AbortError') {
                        // normal if we aborted
                        break;
                    }
                    console.log('Download error:', err);
                    break;
                }
            }
        };

        // Spawn multiple concurrent connections
        const tasks: Promise<void>[] = [];
        for (let i = 0; i < DOWNLOAD_CONNECTIONS; i++) {
            tasks.push(downloadWorker(i));
        }

        // Stop after a fixed duration
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                isActive = false;
                // abort all ongoing fetches
                controllers.forEach((c) => c.abort());
                resolve();
            }, DOWNLOAD_DURATION * 1000);
        });

        // Wait for all tasks to complete
        await Promise.all(tasks);

        setDownloadStarted(false);
        setIsDownloadComplete(true);
    };

    // ------------------------------------------------------------------------
    // Upload Test (concurrency-based)
    // ------------------------------------------------------------------------
    const startUploadTest = async (serverUrl: string) => {
        setUploadStarted(true);
        setUploadSpeed(0);
        setIsUploadComplete(false);

        let isActive = true;
        let totalBytes = 0;
        let speedSamples: number[] = [];

        // We'll upload smaller random blobs in a loop
        const createSmallBlob = () => createRandomBlob(768 * 1024); // 256 KB

        const startTime = performance.now();
        // Single upload worker
        const uploadWorker = async () => {
            while (isActive) {
                try {
                    const testBlob = createSmallBlob();
                    const testFile = new File([testBlob], 'upload_test.bin', { type: 'application/octet-stream' });

                    const formData = new FormData();
                    formData.append('file', testFile);

                    const resp = await fetch(`${serverUrl}/web/upload/?token=${token}&nocache=${Date.now()}`, {
                        method: 'POST',
                        body: formData
                    });
                    if (!resp.ok) break;

                    totalBytes += testFile.size;
                    const elapsedMs = performance.now() - startTime;
                    const elapsedSec = elapsedMs / 1000;
                    const currentSpeed = (totalBytes * 8) / (elapsedSec * 1e6); // Mbps

                    const avgSpeed = updateRollingAverage(speedSamples, currentSpeed);
                    setUploadSpeed(Number(avgSpeed.toFixed(2)));

                } catch (err) {
                    console.log('Upload error:', err);
                    break;
                }
            }
        };

        // Spawn multiple concurrent connections
        const tasks: Promise<void>[] = [];
        for (let i = 0; i < UPLOAD_CONNECTIONS; i++) {
            tasks.push(uploadWorker());
        }

        // Stop after a fixed duration
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                isActive = false;
                resolve();
            }, UPLOAD_DURATION * 1000);
        });

        await Promise.all(tasks);

        setUploadStarted(false);
        setIsUploadComplete(true);
    };

    // ------------------------------------------------------------------------
    // Orchestration
    // ------------------------------------------------------------------------
    const startSpeedTest = async () => {
        setDownloadSpeed(null);
        setUploadSpeed('');
        setIsDownloadComplete(false);
        setIsUploadComplete(false);
        setLatency(null);
        setServerName('');
        setDownloadStarted(false);
        setUploadStarted(false);
        setServerErrorVisible(false);
        setIsUploadComplete(false);
        setSuccessPost(false);

        checkToken();

        setIsSpeedTestVisible(true);

        // Ping all servers and pick the best
        try {
            let bestServer:  {
                name: string,
                url: string,
                socketUrl: string
            };
            if (!server)    {
                const latencies: number[] = [];
                for (const server of serverList) {
                    try {
                        const pingVal = await pingServer(server.socketUrl);
                        latencies.push(pingVal);
                    } catch (err) {
                        latencies.push(Number.MAX_SAFE_INTEGER);
                    }
                }
                const bestServerIndex = latencies.indexOf(Math.min(...latencies));
                bestServer = serverList[bestServerIndex];
                setLatency(latencies[bestServerIndex]);
                setServerName(bestServer.name);
                logger.log(`Best server: ${bestServer.name}, lat ${latencies[bestServerIndex]} ms`);
            } else {
                bestServer = serverList[parseInt(server)];
                if (!bestServer) {
                    throw new Error('Server non valido');
                }
                setServerName(bestServer.name);
                logger.log(`Best server: ${bestServer.name}`);
            }

            // Preliminary speed tests
            setPreliminaryStarted(true);
            const estimatedSpeed = await performMultipleTests(bestServer.url);
            setPreliminaryStarted(false);

            // Decide file size
            let fileSize = []
            if (estimatedSpeed <= 30) {
                fileSize = fileSizeMap.FTTC_1;
            } else if (estimatedSpeed <= 50) {
                fileSize = fileSizeMap.FTTC_2;
            } else if (estimatedSpeed <= 100) {
                fileSize = fileSizeMap.FTTC_3;
            } else if (estimatedSpeed <= 300) {
                fileSize = fileSizeMap.FTTC_4;
            } else {
                fileSize = fileSizeMap.FTTH;
            }
            logger.log(`Chosen file size: ${fileSize} MB`);

            // Start parallel download for fixed duration
            await startDownloadTest(bestServer.url, fileSize);

            // Start parallel upload for fixed duration
            await startUploadTest(bestServer.url);

        } catch (error) {
            console.log(error);
            setServerErrorVisible(true);
        }
    };

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <div className='bg-primary min-h-dvh md:min-h-screen flex flex-col relative overflow-x-hidden'>
            <span className='absolute p-3 md:p-6 flex items-center justify-center w-full'>
                {isUploadComplete === true && successPost === true && countLeft > 0 && (
                    <button
                        className='flex items-center justify-center bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 py-2 px-4 rounded-2xl text-lg text-white font-medium hover:bg-gradient-to-bl hover:from-enelGreen-600 hover:to-enelLightBlue-500'
                        onClick={startSpeedTest}
                    >
                        Ripeti test ({countLeft} {countLeft === 1 ? 'tentativo rimasto' : 'tentativi rimasti'})
                    </button>
                )}
                {countLeft === 0 && successPost === true && (
                    <span className='flex items-center justify-center bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 py-2 px-4 rounded-2xl text-lg text-white font-medium'>
                        Tentativi esauriti
                    </span>
                )}
            </span>
            {!isModalOpen && isCheckComplete && (
                <>
                    <div className="flex-1 flex justify-center items-center bg-gray-100 h-[calc(100%-80px)] py-[66px]">
                        {!isSpeedTestVisible && (
                            <button
                                onClick={startSpeedTest}
                                className='neu-button flex h-[140px] w-[140px] md:h-[200px] md:w-[200px] rounded-full uppercase text-lg font-bold p-[4px] bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400'
                            >
                                <span className='group flex w-full h-full items-center justify-center neu-button-background rounded-full hover:bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 hover:text-white uppercase'>
                                    <span className="bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 inline-block text-transparent bg-clip-text group-hover:text-white">inizia test</span>
                                </span>
                            </button>
                        )}
                        {isSpeedTestVisible && (
                            <SpeedTest
                                preliminaryStarted={preliminaryStarted}
                                downloadSpeed={downloadSpeed as number}
                                uploadSpeed={uploadSpeed as number}
                                serverName={serverName}
                                isDownloadComplete={isDownloadComplete}
                                isUploadComplete={isUploadComplete}
                                ipAddress={ipAddress}
                                downloadStarted={downloadStarted}
                                uploadStarted={uploadStarted}
                                latency={latency}
                                token={token}
                                tokenId={tokenId}
                                onTokenCheck={checkTokenCount}
                                setSuccessPost={setSuccessPost}
                            />
                        )}
                    </div>
                </>
            )}
            {isModalOpen && isCheckComplete && (
                <div className="modal">
                    <div className="modal-content w-[90%] sm:w-[400px] flex flex-col gap-3 items-center justify-center">
                        <h2 className='uppercase font-bold text-xl'>Attenzione!</h2>
                        <p>{statusError}</p>
                        <button
                            className='flex aspect-square items-center justify-center w-[50px] rounded-full bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 hover:from-enelGreen-500 hover:to-enelGreen-500 mt-2'
                            onClick={() => {
                                setModalOpen(false);
                                setCheckComplete(false);
                                if (redirectUrl) {
                                    navigate(redirectUrl);
                                } else {
                                    navigate('/');
                                }
                            }}
                        >
                            <ReplayIcon className='reload-icon' />
                        </button>
                    </div>
                </div>
            )}
            {serverErrorVisible && (
                <div className="modal">
                    <div className="modal-content w-[90%] sm:w-[400px] flex flex-col gap-3 items-center justify-center">
                        <h2 className='uppercase font-bold text-xl'>Attenzione!</h2>
                        <p>Siamo spiacenti, il servizio non è al momento disponibile.</p>
                    </div>
                </div>
            )}
        </div>
    );
}

import { useEffect, useState } from 'react';
import './TokenPage.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function TokenPage() {

    const tokenUrl = 'https://speedtestroma.enel.it/tokens/temporary'
    const navigate = useNavigate();


    const [isCodeVisible, setIssCodeVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    async function getToken() {
        setIsLoading(true)
        try {
            const response = await axios.get(tokenUrl);
            if (response && response.data && response.data.token) {
                setToken(response.data.token);
                setIssCodeVisible(true)
                setIsLoading(false)
            } else {
                console.error("Token not found in the response:", response.data);
            }
        } catch (error: any) {
            // if (error.response) {
            //     Errore con risposta dal server (4xx o 5xx)
            //     console.error("Server responded with status:", error.response.status);
            //     console.error("Response data:", error.response.data);
            // } else if (error.request) {
            //     Nessuna risposta ricevuta
            //     console.error("No response received:", error.request);
            // } else {
            //     Altro tipo di errore
            //     console.error("Error setting up the request:", error.message);
            // }
        }
    }


    const goToSpeedTest = () => {
        navigate(`/speed-test/${token}`); // Reindirizza con il token nell'URL
    };

    useEffect(() => {
        getToken()
    }, []);


    return (
        <div className='bg-primary h-dvh md:h-screen flex flex-col w-full'>
            <div className="flex-1 flex justify-center items-center bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 w-full">
                {/* {isCodeVisible && ( */}
                    <div className='w-[90%] md:w-[400px] text-center flex flex-col items-center justify-between gap-3 leading-none h-full !text-black'>
                        <p className='py-6 text-xl uppercase'>speed Test</p>
                        <span className='flex flex-col items-center justify-center gap-6'>
                            <p className='text-xl'>Comunica questo codice all'operatore</p>
                            <span className='min-h-[80px] flex items-center justify-center'>
                            {isLoading ? (
                                <CircularProgress size={50} className={`w-[10px] !text-white mb-[10px]`}/>
                            ) : (
                                <p className='text-[80px] font-number stroke-black text-stroke word-spacing'>{token.slice(0, 2) + " " + token.slice(2, 4) + " " + token.slice(4, 6)}</p>
                            )}
                            </span>
                            <span className='mt-[-20px]'>Hai già un codice?<br></br><span className='cursor-pointer underline' onClick={() => setModalOpen(true)}>Inseriscilo</span> per iniziare lo speed test</span>
                        </span>
                        <span className='py-6 flex flex-col items-center justify-center gap-3'>
                            <p className='text-base md:text-xl'>Quando l'operatore ti comunicherà l'ok, clicca sul pulsante "Verifica"</p>
                            <button onClick={goToSpeedTest} className='bg-white py-2 px-6 text-base md:text-xl cursor-pointer rounded-full border-4 border-enelLightBlue-500 hover:border-enelGreen-500'>Verifica</button>
                        </span>
                    </div>
                {/* )} */}
            </div>
            <>
                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content w-[90%] sm:w-[400px] flex flex-col gap-3 items-center justify-center">
                            <h2 className='text-xl'>Inserisci il codice</h2>
                            <input
                                type="text"
                                // value={token}
                                onChange={(e) => setToken(e.target.value)}
                                className="w-full border border-gray-300 p-2 text-center rounded-full"
                                placeholder="Inserisci il codice"
                            />
                            <button onClick={goToSpeedTest} className='py-2 px-6 text-base md:text-xl cursor-pointer rounded-full text-white bg-gradient-to-bl from-enelGreen-500 to-enelLightBlue-400 hover:from-enelGreen-500 hover:to-enelGreen-500'>Verifica</button>
                            <span>oppure <span onClick={() => window.location.reload()} className='text-enelLightBlue-500 font-medium cursor-pointer'>torna indietro</span>.</span>
                        </div>
                    </div>
                )}
            </>
        </div>

    )
}
